import * as React from "react"
import { ctaText } from "./cta-card.css"
import { Box, Button, Flex, Text } from "./ui"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { theme } from "../theme.css"

export default function CtaCard(props) {
  console.log(props)
  const options = {
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <Text variant="heading" style={{ color: theme.colors.white }}>
            {children}
          </Text>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => (
        <Text variant="subhead" style={{ color: theme.colors.white }}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Text variant="subheadSmall" style={{ color: theme.colors.white }}>
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Text variant="subheadSmall" style={{ color: theme.colors.white }}>
          {children}
        </Text>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text variant="body" style={{ color: theme.colors.white }}>
          {children}
        </Text>
      ),
    },
  }
  return (
    <Box
      radius={"card"}
      background={"ctaCardBackground"}
      paddingX={4}
      paddingY={4}
    >
      <Flex responsiveMedium>
        <Box>
          <div className={ctaText}>
            {documentToReactComponents(JSON.parse(props.ctaText.raw), options)}
          </div>
          {/* <Text
            className={ctaText}
            variant="cardBody"
            dangerouslySetInnerHTML={{
              __html: props.body,
            }}
          /> */}
        </Box>
        <Box width="third" textCenter>
          <Button to={props.link.href}>{props.link.text}</Button>
        </Box>
      </Flex>
    </Box>
  )
}
